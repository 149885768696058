<template>

    <div>

        <v-container fluid>

            <v-form ref="form">
                <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                    <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Personal Details</v-subheader>
                </v-row>
                <v-row align="center">
                    <v-text-field
                        ref="first_name"
                        v-model="first_name"
                        :rules="[rules.required]"
                        color="csb_yellow"
                        label="First Name">
                    </v-text-field>
                </v-row>

                <v-row align="center">
                    <v-text-field
                            ref="middle_name"
                            v-model="middle_name"
                            color="csb_yellow"
                            label="Middle Name">
                    </v-text-field>
                </v-row>

                <v-row align="center">
                    <v-col cols="8" class="pr-2" style="padding: 0px;">
                        <v-text-field
                            ref="last_name"
                            v-model="last_name"
                            :rules="[rules.required]"
                            color="csb_yellow"
                            label="Last Name">
                        </v-text-field>
                    </v-col>
                    <v-col  cols="4" style="padding: 0px;">
                        <v-text-field
                            ref="suffix"
                            v-model="suffix"
                            color="csb_yellow"
                            label="Suffix">
                        </v-text-field>
                    </v-col>      
                </v-row>
                
              
                <v-row align="center">

                    <v-dialog
                        ref="dialog_birthdate"
                        v-model="modal_birthdate"
                        :return-value.sync="birthdate"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                color="csb_yellow"
                                v-model="birthdate"
                                label="Birthdate"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            color="csb_yellow"
                            ref="picker_birthdate"
                            v-model="birthdate"
                            :max="max_date"
                            min="1930-01-01"
                            @change="$refs.dialog_birthdate.save(birthdate)"
                            >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="csb_yellow"
                            @click="modal_birthdate = false, $refs.picker_birthdate.activePicker = 'YEAR'"
                        >
                            Cancel
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-autocomplete 
                        autocomplete="off"
                        ref="birth_place_province"
                        :rules="[rules.required]"
                        :loading="birth_place_province_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="birth_place_province" 
                        :items="birth_place_province_options" 
                        :search-input.sync="search_birth_place_province"
                        item-text="Title" 
                        item-value="MappingId" 
                        label="Birth Place Province"
                        @change="birthPlaceProvinceOnChange()">
                    
                    </v-autocomplete>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-autocomplete 
                        autocomplete="off"
                        ref="birth_place_city"
                        :rules="[rules.required]"
                        :loading="birth_place_city_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="birth_place_city" 
                        :items="birth_place_city_options" 
                        :search-input.sync="search_birth_place_city"
                        item-text="Title" 
                        item-value="MappingId" 
                        label="Birth Place City"
                        :disabled="disable_birth_place_city">

                    </v-autocomplete>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-col style="padding: 0px;">
                        <v-select 
                            color="csb_yellow"
                            v-model="civil_status"
                            :loading="civil_status_loading"
                            :rules="[rules.required]"
                            :items="civil_status_options" 
                            item-text="display" 
                            item-value="value" 
                            label="Civil Status" 
                            clearable>
                        </v-select>
                    </v-col>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-col style="padding: 0px;">
                        <v-select 
                            color="csb_yellow"
                            v-model="gender" 
                            :rules="[rules.required]"
                            :items="[{display: 'Male', value: 'Male'}, {display: 'Female', value: 'Female'}]" 
                            item-text="display" 
                            item-value="value" 
                            label="Gender" 
                            clearable>
                        </v-select>
                    </v-col>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-text-field
                        ref="mobile_no"
                        prefix="+63"
                        pattern="\d*" 
                        type="tel"
                        counter="10"
                        v-model="mobile_no"
                        :rules="[rules.required, rules.mobile_no_length, rules.mobile_no_prefix]"
                        color="csb_yellow"
                        label="Mobile No"
                        @keydown="isNumber('mobile_no')"
                        required>
                    </v-text-field>
                </v-row>

                <v-row align="center" class="mb-4" style="margin-top: -6px;">
                    <v-text-field
                        ref="email_address"
                        type="email"
                        v-model="email_address"
                        :rules="[rules.required, rules.email_address]"
                        color="csb_yellow"
                        label="Email Address"
                        required>
                    </v-text-field>
                </v-row>

                <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                    <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Present Address</v-subheader>
                </v-row>

                <v-row align="center">
                    <v-autocomplete 
                        autocomplete="province"
                        ref="province"
                        :rules="[rules.required]"
                        :loading="province_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="province" 
                        :items="province_options" 
                        :search-input.sync="search_province"
                        item-text="Title" 
                        item-value="MappingId" 
                        label="Province"
                        @change="provinceOnChange()"
                        >
                    
                    </v-autocomplete>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-autocomplete 
                        autocomplete="city"
                        ref="city"
                        :rules="[rules.required]"
                        :loading="city_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="city" 
                        :items="city_options" 
                        :search-input.sync="search_city"
                        item-text="Title" 
                        item-value="MappingId" 
                        label="City"
                        :disabled="disable_city"
                        @change="cityOnChange()"
                        >
                    
                    </v-autocomplete>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-autocomplete 
                        autocomplete="barangay"
                        ref="barangay"
                        :rules="[rules.required]"
                        :loading="barangay_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="barangay" 
                        :items="barangay_options" 
                        :search-input.sync="search_barangay"
                        item-text="Title" 
                        item-value="SysId" 
                        label="Barangay"
                        :disabled="disable_barangay">
                    
                    </v-autocomplete>
                </v-row>
                <v-row align="center">
                    <v-text-field
                        ref="house_number"
                        v-model="house_number"
                        :rules="[rules.required]"
                        color="csb_yellow"
                        label="House Number">
                    </v-text-field>
                </v-row>
                <v-row align="center">
                    <v-text-field
                        ref="street"
                        v-model="street"
                        :rules="[rules.required]"
                        color="csb_yellow"
                        label="Street"
                        required>
                    </v-text-field>
                </v-row>

                <v-row align="center">

                    <v-dialog
                        ref="dialog_residence_date"
                        v-model="modal_residence_date"
                        :return-value.sync="residence_date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                color="csb_yellow"
                                :rules="[rules.required]"
                                v-model="residence_date"
                                label="Residence Since"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            type="month"
                            color="csb_yellow"
                            ref="picker_residence_date"
                            v-model="residence_date"
                            :max="new Date().toISOString().substr(0, 10)"
                            min="1930-01-01"
                            @change="$refs.dialog_residence_date.save(residence_date + '-01')"
                            >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="csb_yellow"
                            @click="modal_residence_date = false, $refs.picker_residence_date.activePicker = 'YEAR'"
                        >
                            Cancel
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-row>

                <v-row align="center" class="mb-4" style="margin-top: -6px;">
                    <v-col style="padding: 0px;">
                        <v-select 
                            :rules="[rules.required]"
                            :loading="ownership_type_loading"
                            color="csb_yellow"
                            v-model="ownership_type" 
                            :items="ownership_type_options" 
                            item-text="display" 
                            item-value="value" 
                            label="Type of Ownership" 
                            clearable>
                        </v-select>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
      
        <v-row  align="center" justify="space-around" class="bottom-nav">
            <v-col class="bottom-nav-item">
                <v-btn @click="handleBack()" depressed class="bottom-nav-btn back-btn">
                    Back
                </v-btn>
            </v-col>
            <v-col class="bottom-nav-item">
                <v-btn @click="handleNext()" depressed class="bottom-nav-btn">
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { getLookupValues } from '@/api/profile'
import { mapGetters } from 'vuex'
import moment from 'moment'

const validPrefixes = [
    '905','906','915','916','917','926','927','935','936','945','953','954','955','956','963','964','965','966','967', 
    '975','976','977','994','995','996','997','817','813','900','907','908','909','910','911','912','913','914','918','919','920','921','928',
    '929','930','938','939','940','946','947','948','949','950','951','957','958','959','960','961','968','969','970','971','980','981','982','985',
    '989','992','998','999','922','923','924','925','931','932','933','934','941','942','943','944','951','952','962','971','972',

]

const mustBeValidPrefix = (value) => {
    var prefix = value.substring(0, 3);
    var res = validPrefixes.indexOf(prefix) > -1
    return res
}
   

export default {
    computed: {

        ...mapGetters([
            'profile',
            'othersFormIsValid'
        ]),
    },
    beforeRouteLeave(to, from, next) {

        if(to.name == 'Calculator'){

            var profileFormIsValid = this.$refs.form.validate()
            this.$store.dispatch('user/profileFormIsValid', profileFormIsValid)
            next()
            
        } else {

            if(this.$refs.form.validate()){

                var valid = moment(this.residence_date, 'YYYY-MM-DD',true).isValid()

                if(!valid){
                    this.residence_date = this.residence_date + '-01'
                }

                var data = {
                    first_name: this.first_name,
                    middle_name: this.middle_name,
                    last_name: this.last_name,
                    suffix: this.suffix,
                    birthdate: this.birthdate,
                    modal_birthdate: this.modal_birthdate,
                    cancel_birthdate: this.cancel_birthdate,
                    birth_place_province: this.birth_place_province,
                    search_birth_place_province: this.search_birth_place_province,
                    birth_place_province_options: this.birth_place_province_options,
                    birth_place_city: this.birth_place_city,
                    birth_place_city_options: this.birth_place_city_options,
                    search_birth_place_city: this.search_birth_place_city,
                    disable_birth_place_city: this.disable_birth_place_city,
                    civil_status: this.civil_status,
                    civil_status_options: this.civil_status_options,
                    gender: this.gender,
                    gender_options: this.gender_options,
                    mobile_no: this.mobile_no,
                    email_address: this.email_address,
                    province: this.province,
                    search_province: this.search_province,
                    province_options: this.province_options,
                    city: this.city,
                    search_city: this.search_city,
                    city_options: this.city_options,
                    disable_city: this.disable_city,
                    barangay: this.barangay,
                    search_barangay: this.search_barangay,
                    barangay_options: this.barangay_options,
                    disable_barangay: this.disable_barangay,
                    house_number: this.house_number,
                    street: this.street,
                    residence_date: this.residence_date,
                    modal_residence_date: this.modal_residence_date,
                    ownership_type: this.ownership_type,
                    ownership_type_loading: this.ownership_type_Loading,
                    ownership_type_options: this.ownership_type_options,
                    birth_place_province_loading: this.birth_place_province_loading,
                    birth_place_city_loading: this.birth_place_city_loading,
                    province_loading: this.province_loading,
                    city_loading: this.city_loading,
                    barangay_loading: this.barangay_loading,
                }

                this.$store.dispatch('user/profileFormIsValid', true)

                if(to.name == 'Others'){
                    next();
         
                } else if(to.name == 'Attachment'){

                    if(this.othersFormIsValid){
                        next();
                    }
                }
                
                this.$store.dispatch('user/profile', data)

            }
        } 
      
    },
  
    data: function() {
        return {
            first_name: '',
            middle_name: '',
            last_name: '',
            suffix: '',
            birthdate: null,
            modal_birthdate: false,
            cancel_birthdate: false,
            birth_place_province: null,
            search_birth_place_province: null,
            birth_place_province_options: [],
            birth_place_city: null,
            birth_place_city_options: [],
            search_birth_place_city: null,
            disable_birth_place_city: true,
            civil_status: null,
            civil_status_options: [],
            gender: null,
            gender_options: [], 
            mobile_no: '',
            email_address: '',
            province: null,
            search_province: null,
            province_options: [],
            city: null,
            search_city: null,
            city_options: [],
            disable_city: true,
            barangay: null,
            search_barangay: null,
            barangay_options: [],
            disable_barangay: true,
            house_number: '',
            street: '',
            residence_date: null,
            modal_residence_date: false,
            ownership_type: '',
            ownership_type_options: [],
            birth_place_province_loading: true,
            birth_place_city_loading: false,
            province_loading: true,
            city_loading: false,
            barangay_loading: false,
            ownership_type_loading: true,
            civil_status_loading: true,


            //rules
            rules: {
                required: v => !!v || 'Required.',
                mobile_no_length:  v => v.length == 10 || 'Mobile no must be 10 digits',
                mobile_no_prefix:  v => mustBeValidPrefix(v) || 'Invalid mobile no prefix',
                email_address: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid email address format',
            },
        }
    },

    watch: {
        modal_birthdate (val) {
            val && setTimeout(() => (this.$refs.picker_birthdate.activePicker = 'YEAR'))
        },

        modal_residence_date (val) {
            val && setTimeout(() => (this.$refs.picker_residence_date.activePicker = 'YEAR'))
        },

    },
  
    methods:{
        handleBack(){

            this.$router.push({ path: '/calculator' })

        },
        handleNext(){

            this.$router.push({ path: '/others/index' })
        },

        fetchLookupValues(value, id=''){
            return getLookupValues(value, id).then(res => {
                return res.data.data
                
            })
        },

        birthPlaceProvinceOnChange(){ // pag nakapili na ng province
            
            if(this.birth_place_province) {
                this.disable_birth_place_city = false
                this.birth_place_city = null
                this.$refs.birth_place_city.reset()
                this.birth_place_city_loading = true
                this.fetchLookupValues('city', this.birth_place_province).then(res => {
                    var birth_place_cities = res
                    birth_place_cities.sort((a,b) => (a.Title > b.Title) ? 1 : ((b.Title > a.Title) ? -1 : 0)); // sort by name
                    this.birth_place_city_options = birth_place_cities
                    this.birth_place_city_loading = false
                })
            } else {

                this.disable_birth_place_city = true
                this.birth_place_city = null
                this.birth_place_city_options = []
            }

        },

        provinceOnChange(){

            if(this.province) {
                this.disable_city = false
                this.city = null
                this.$refs.city.reset()
                this.city_loading = true
                this.fetchLookupValues('city', this.province).then(res => {
                    var cities = res
                    cities.sort((a,b) => (a.Title > b.Title) ? 1 : ((b.Title > a.Title) ? -1 : 0)); // sort by name
                    this.city_options = cities
                    this.city_loading = false
                })
            } else {

                this.disable_city = true
                this.city = null
                this.city_options = []

            }
            // disable barangay on province change
            this.disable_barangay = true
            this.barangay = null
            this.barangay_options = []

        },

        cityOnChange(){

            if(this.city) {
                this.disable_barangay = false
                this.barangay = null
                this.$refs.barangay.reset()
                this.barangay_loading = true
                this.fetchLookupValues('brgy', this.city).then(res => {
                    var barangays = res
                    barangays.sort((a,b) => (a.Title > b.Title) ? 1 : ((b.Title > a.Title) ? -1 : 0)); // sort by name
                    this.barangay_options = barangays
                    this.barangay_loading = false
                })
            } else {

                this.disable_barangay = true
                this.barangay = null
                this.barangay_options = []

            }

        },
        isNumber: function(field){
            var charCode = (event.which) ? event.which : event.keyCode;
            
            if ((charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) && charCode !== 46 && charCode !== 188 && charCode !== 190) {
                event.preventDefault();
            } else {
                event.preventDefault();
                var valueString = event.target.value

                if(charCode==8 || charCode==46)
                    valueString = valueString.slice(0, -1)
               
                else if(field=='mobile_no'){
                    valueString = valueString + event.key
                    if(valueString.length > 10) return false
                }

                var str = valueString.replace(/[^0-9]/g,'')
                this[field] = str
            }
        },
      
    },
    created() {
        var year = new Date().getFullYear() - 18
        var month = new Date().getMonth() + 1
        var day = new Date().getDate()

        year = year.toString()
        month = month.toString()
        day = day.toString()

        this.max_date = year+'-'+month+'-'+day

        this.fetchLookupValues('province').then(res => {
            var provinces = res
            provinces.sort((a,b) => (a.Title > b.Title) ? 1 : ((b.Title > a.Title) ? -1 : 0)); // sort by name

            this.province_options = provinces
            this.birth_place_province_options = provinces
            this.province_loading = false
            this.birth_place_province_loading = false
        })

        this.fetchLookupValues('civil_status').then(res => {
            this.civil_status_loading = false
            this.civil_status_options = res
        })

        this.fetchLookupValues('type_of_ownership').then(res => {
            this.ownership_type_loading = false
            this.ownership_type_options = res
        })

    },

}
</script>